// @tailwind base;
// @tailwind components;
// @tailwind utilities;

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

// Custom Styles
@import "custom/_styles";

// Custom Variables

html {
  font-family: 'Open Sans', sans-serif;
  scroll-behavior: smooth;
}
.zalo-chat-widget {
  bottom: 92px !important;
  right: 28px !important;
  @media (max-width: 768px) {
    bottom: 65px !important;
    right: 20px !important;
  }
}
